<template>
  <div>
    <el-container>
      <common-tree
        ref="commonTree"
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :loading="treeDialogLoading"
        :showCheckbox="false"
        :isShowdig="false"
        treeTitle="题库分类"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          head-title="试卷"
          :head-btn-options="headBtnOptions"
          @head-back="headBack"
        />
        <div class="headers">
          <grid-head-layout
            ref="gridHeadLayout"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        </div>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
        >
          <template #customBtn="{row}">
            <el-button
              size="small"
              icon="el-icon-edit"
              type="text"
              v-if="permissionList.editBtn"
              @click="answer(row)"
            >开始答题
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import {getEduTree} from "@/api/training/edutree";
import {getEduList} from "@/api/training/edupaper";
import {mapGetters} from "vuex";


export default {
  name: "edupaperselect",
  components: {
    CommonTree,
    GridLayout,
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        label: "title",
        value: "id",
        key: "id"
      },
      searchTitle: "",
      treeDialogLoading: false,
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true)
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: '返回',
          emit: "head-back",
          type: "button",
          btnOptType: 'back',
        });
      return buttonBtn;
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷名称",
          prop: "paperName",
        },
      ]
    },
    tableOptions() {
      return {
        size: 'small',
        selection: false,
        index: true,
        column: [
          {
            label: "试卷名称",
            prop: "paperName",
            sortable: true,
            overHidden: true,
          },
          {
            label: "试卷说明",
            prop: "paperExplain",
            sortable: true,
            overHidden: true,
          },
          {
            label: "答题时长",
            prop: "answerTime",
            sortable: true,
            width: 120,
            align: 'right',
          },
        ]
      }
    }
  },
  methods: {
    treeNodeClick(row) {
      this.query.subjectType = row.id
      this.subjectType = row.id
      this.subjectName = row.title
      this.page.currentPage = 1
      this.onLoad(this.page)
    },
    initTree() {
      this.treeDialogLoading = true
      getEduTree()
        .then(res => {
          const {data} = res.data
          this.treeData = data;
          const columns = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
          this.treeParentIdDicData = res.data.data
          columns.dicData = res.data.data
        }).finally(() => {
        this.treeDialogLoading = false
      })
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page)
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoadEdu(page, params = {}) {
      this.loading = true;
      this.query.paperStatus = "1"
      getEduList(page.currentPage, page.pageSize, Object.assign(params, this.query))
        .then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
          this.$refs.gridLayOut.selectionClear();
        });
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.query.paperStatus = "1"
      getEduList(page.currentPage, page.pageSize, Object.assign(params, this.query))
        .then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
          this.$refs.gridLayOut.selectionClear();
        });
    },
    answer(row) {
      this.$router.push({
        path: '/training/exam/eduexampaper',
        query: {
          row: JSON.stringify(row),
        }
      });
    },
    headBack() {
      this.$router.push({
        path: "/business/training/exam/examTabs",
      });
    },
  },
  mounted() {
    this.initTree()
  },
  created() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
