var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("common-tree", {
            ref: "commonTree",
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              loading: _vm.treeDialogLoading,
              showCheckbox: false,
              isShowdig: false,
              treeTitle: "题库分类",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "试卷",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-back": _vm.headBack },
              }),
              _c(
                "div",
                { staticClass: "headers" },
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                ],
                1
              ),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _vm.permissionList.editBtn
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  icon: "el-icon-edit",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.answer(row)
                                  },
                                },
                              },
                              [_vm._v("开始答题\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }